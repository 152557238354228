import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import ScrollAnimation from 'react-animate-on-scroll';
import Paragraph from "../components/story/paragraph"
import Heading from "../components/story/heading"

// Custom components
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Tagline from "../components/services/tagline";
import Wrapper from "../components/layout/wrapper"
import VideoSection from "../components/services/videoSection";

// Static files
import Line from "../images/line-sports.svg"
const LineData = (
  [Line, 'top-0', 'right-36', 'scale-y-104', '']
)

export const query = graphql`
query {
    allMdx(filter: {frontmatter: {templateKey: {eq: "services"}}}) {
        nodes {
            frontmatter {
                templateKey
                sports {
                    metaDescription
                    metaTitle
                    tagline
                    youtubeVideo
                    intro {
                        heading
                        text
                        imageAlt
                        imageUrl
                        image {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }
    }
}
` 
const IndexPage = ({ data }) => {
    const allData = data?.allMdx?.nodes[0]?.frontmatter?.sports
    const allIntro = allData?.intro 
    const Stories = () => (
    <div className={`flex flex-col items-center xl:items-start justify-between gap-y-20 xl:flex-row`}>
        <div className={`text-center xl:text-left flex-1`}>
            <Heading
                text={allIntro[0]?.heading}
            />
            <Paragraph
                text={allIntro[0]?.text}
            />
        </div>
        <div className={`flex flex-1 justify-end`}>
            <ScrollAnimation animateIn="bounceInUp" duration={1.5} animateOnce>
                <a target="_blank" rel="noreferrer" href={allIntro[0]?.imageUrl} title={allIntro[0]?.imageAlt}>
                    <GatsbyImage
                        image={allIntro[0]?.image?.childImageSharp.gatsbyImageData}
                        alt={allIntro[0]?.imageAlt}
                        className={`max-w-[820px]`}
                    />
                </a>
            </ScrollAnimation>
        </div>
    </div>
  )
  return (
    <Layout line={LineData} fullWidth>
        <Seo 
            title={allData?.metaTitle}
            description={allData?.metaDescription}
        />
        <Wrapper>
          <div className="flex flex-col gap-y-32 my-16">
            <Stories/>
          </div>
        </Wrapper>
        <Tagline 
          tagline={allData?.tagline}
        />
        <VideoSection src={allData?.youtubeVideo} />
    </Layout>
  )
}

export default IndexPage
