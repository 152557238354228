import * as React from "react"
import Vid from "./videoPlayer"

const VideoSection = ({ src, provider, isAutoplay }) => {
    return (
          <div className="video-section">
            <Vid src={src}/>
          </div>
    )
}

export default VideoSection